import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getPowers(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`powers-of-attorney${query}`)
  },

  async storePower(data) {
    return await apiInstance.post('powers-of-attorney', data)
  },

  async getPowerInfo(id) {
    return await apiInstance.get(`powers-of-attorney/${id}`)
  },

  async updatePower(data) {
    return await apiInstance.put(`powers-of-attorney/${data.id}`, data)
  },

  async deletePower(data) {
    return await apiInstance.delete(`powers-of-attorney/${data.id}`)
  },
}
